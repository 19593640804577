import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="500.000000pt"
    height="500.000000pt"
    viewBox="0 0 500.000000 500.000000"
    id="logo"
    preserveAspectRatio="xMidYMid meet">
    <title>Logo</title>
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none">
      <path
        d="M378 2991 c-29 -26 -33 -35 -33 -81 0 -46 4 -55 33 -81 63 -57 153
-29 167 51 19 114 -86 184 -167 111z"
      />
      <path
        d="M910 2686 c-71 -27 -86 -35 -116 -63 -83 -76 -119 -165 -117 -288 1
-201 125 -327 323 -329 178 -2 292 94 330 279 25 121 -18 268 -99 335 -84 70
-230 100 -321 66z m160 -111 c32 -29 62 -146 62 -242 0 -118 -27 -194 -78
-218 -89 -42 -158 38 -179 211 -12 99 28 237 77 262 30 15 95 8 118 -13z"
      />
      <path
        d="M1810 2693 c-8 -3 -37 -16 -64 -29 -50 -24 -65 -21 -66 14 0 16 -55
15 -105 -3 -22 -8 -68 -14 -102 -15 l-63 0 0 -55 0 -55 46 0 46 0 -4 -192 c-2
-106 -8 -201 -12 -211 -5 -10 -21 -21 -37 -24 -27 -5 -29 -9 -29 -49 l0 -44
175 0 175 0 0 50 0 50 -40 0 -40 0 0 198 0 198 39 22 c52 29 102 29 138 -2
l28 -24 3 -185 c3 -160 1 -187 -13 -201 -9 -9 -25 -16 -36 -16 -16 0 -19 -7
-19 -45 l0 -45 170 0 170 0 0 50 0 50 -37 0 -38 0 0 203 0 202 -29 59 c-25 50
-35 61 -80 82 -49 23 -137 31 -176 17z"
      />
      <path
        d="M4348 2671 l-68 -30 0 24 c0 22 -4 25 -39 25 -21 0 -55 -7 -74 -15
-20 -8 -66 -15 -102 -15 l-65 0 0 -55 0 -55 45 0 45 0 0 -180 c0 -206 -10
-250 -54 -250 -24 0 -26 -3 -26 -45 l0 -45 175 0 175 0 0 50 0 50 -40 0 -40 0
0 200 0 200 40 20 c74 38 135 21 160 -46 6 -14 10 -96 10 -182 0 -168 -9 -202
-51 -202 -16 0 -19 -7 -19 -45 l0 -45 175 0 175 0 0 50 0 50 -43 0 -42 0 3
143 c8 321 -39 417 -208 424 -54 3 -76 -2 -132 -26z"
      />
      <path
        d="M415 2675 c-16 -8 -60 -14 -97 -15 l-68 0 0 -55 0 -55 45 0 45 0 0
-193 c0 -210 -6 -237 -56 -237 -22 0 -24 -4 -24 -45 l0 -45 175 0 175 0 0 50
0 50 -40 0 -40 0 0 280 0 280 -42 -1 c-24 0 -56 -7 -73 -14z"
      />
      <path
        d="M2200 2625 c0 -54 1 -56 26 -53 25 3 28 -2 45 -57 10 -33 39 -118 64
-190 26 -71 53 -150 61 -175 8 -25 22 -64 31 -88 l16 -43 69 3 70 3 39 120
c21 66 49 156 62 200 l24 80 38 -120 c21 -66 51 -156 65 -200 l28 -80 70 -3
71 -3 14 33 c8 19 38 103 67 188 77 228 99 281 133 318 31 32 57 42 57 21 0
-7 11 -9 28 -6 27 5 29 3 41 -42 7 -27 66 -157 132 -290 65 -133 119 -248 119
-256 0 -19 -41 -124 -63 -162 -24 -41 -37 -42 -37 -3 0 23 -7 33 -30 45 -58
30 -120 -13 -120 -84 0 -91 101 -139 194 -91 54 27 108 107 145 218 11 31 41
116 66 187 26 72 53 149 60 172 33 101 88 241 108 270 12 18 32 37 44 42 19 9
23 18 23 56 l0 45 -120 0 -120 0 0 -54 0 -55 36 6 36 6 -17 -33 c-9 -18 -39
-99 -65 -179 l-48 -146 -35 75 c-100 216 -109 246 -82 273 9 10 21 17 26 17 5
0 9 20 9 45 l0 45 -295 0 -295 0 0 -55 0 -56 36 7 c29 5 35 4 31 -7 -3 -8 -13
-39 -22 -69 -10 -30 -30 -95 -45 -145 -16 -49 -30 -99 -32 -110 -4 -20 -14 10
-83 250 -20 72 -40 141 -44 155 -7 23 -12 25 -84 28 l-76 3 -12 -43 c-6 -24
-22 -77 -34 -118 -23 -72 -68 -234 -78 -275 -3 -14 -5 -10 -6 11 0 17 -17 86
-37 153 -38 128 -38 165 -4 176 16 5 20 15 20 51 l0 44 -160 0 -160 0 0 -55z"
      />
    </g>
  </svg>
);

export default IconLogo;
